import React, { ReactElement } from 'react'
import constants from 'style/constants.module.scss'
import { IViewPortColumn } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { ATTACHMENT_COLUMN_TYPES } from 'components/spreadsheet/constants/const'
import { IFile, ICellValue, ITableViewColour, IContextMenuState } from 'types'
import { formatPercentDisplayValue, formatDate, formatDateTime } from 'components/spreadsheet/helpers/format'
import { getCellColour } from 'components/spreadsheet/helpers/colouring'
import { Triangle } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface Props {
  column: IViewPortColumn
  rowHeight: number
  colourSettings: ITableViewColour[]
  groupKey: string
  onGroupToggle: (groupKey: string) => void
  value: ICellValue
  canCollapse: boolean
  isWithinCollapsedGroup?: boolean
  onContext: (menu: IContextMenuState) => void
  shouldShowValue: boolean
}

const GroupCell: React.FC<Props> = ({
  value,
  column,
  rowHeight,
  colourSettings,
  onGroupToggle,
  groupKey,
  canCollapse,
  isWithinCollapsedGroup,
  onContext,
  shouldShowValue
}) => {
  const heightPx = `${getRowHeightVariable(rowHeight)}px`
  const handleToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    onGroupToggle(groupKey)
  }

  const handleOnContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    onContext({
      ...INITIAL_CONTEXT_MENU_STATE,
      open: true,
      top: `${event.clientY + 5}px`,
      left: `${event.clientX - 120}px`,
      right: 'auto',
      bottom: 'auto',
      columnId: column.column.publicId
    })
  }

  const maxWidth = column.column.width - 40

  // This is actually what gets displayed
  let displayValue: ICellValue | ReactElement = value
  let displayStringValue = value ? value.toString() : ''

  if (value !== undefined && value !== null) {
    if (ATTACHMENT_COLUMN_TYPES.includes(column.column.kind)) {
      displayValue = (value as IFile[]).map((file) => file.filename).join(',')
      displayStringValue = displayValue
    } else if (column.column.kind === 'percentage') {
      displayValue = formatPercentDisplayValue(value as number)
      displayStringValue = displayValue
    } else if (column.column.kind === 'date' && column.column.dateFormat) {
      displayValue = formatDate(value as string, column.column.dateFormat)
      displayStringValue = displayValue
    } else if (column.column.kind === 'datetime' && column.column.dateFormat) {
      displayValue = formatDateTime(value as string, column.column.dateFormat)
      displayStringValue = displayValue
    } else if (column.column.kind === 'checkbox') {
      displayValue = <input type="checkbox" checked={value !== null && value === true} disabled={true} />
      displayStringValue = value !== null && value === true ? 'true' : 'false'
    } else if (column.column.kind === 'select') {
      displayValue = (
        <span id="select-value-list" className="flex flex-wrap overflow-hidden p-0">
          <span
            id="select-value-item"
            className="truncate bg-light-grey rounded h-fit border-1px border-solid border-grey"
            style={{
              margin: '2px',
              padding: '0px 3px'
            }}
          >
            {value}
          </span>
        </span>
      )
      displayStringValue = value.toString()
    } else if (column.column.kind === 'multiselect') {
      const stringValue = value && Array.isArray(value) ? (value as Array<string>) : []
      displayValue = (
        <span id="select-value-list" className="flex flex-wrap overflow-hidden p-0">
          {stringValue.map((item: string, itemNumber: number) => {
            return (
              <span
                key={itemNumber}
                id="select-value-item"
                className="truncate bg-light-grey rounded h-fit border-1px border-solid border-grey"
                style={{
                  margin: '2px',
                  padding: '0px 3px',
                  backgroundColor: column ? getCellColour(column.column, item, colourSettings, true) : 'inherit'
                }}
              >
                {item}
              </span>
            )
          })}
        </span>
      )
      displayStringValue = stringValue.join(', ')
    }
  }

  return (
    <div
      style={{
        width: `${column.column.width}px`,
        left: `${column.left}px`,
        height: heightPx,
        position: column.isFrozen ? 'sticky' : 'absolute',
        zIndex: column.isFrozen ? (shouldShowValue ? 4 : 3) : 'initial',
        borderTop: shouldShowValue || isWithinCollapsedGroup ? `1px solid ${constants.darkerGrey}` : 'none',
        backgroundColor:
          column && column.column && column.column.kind !== 'multiselect'
            ? getCellColour(column.column, value, colourSettings, true)
            : constants.lightGrey,
        boxShadow: column.isLastFrozenColumn
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none'
      }}
      className="border-r-1px border-solid border-darker-grey"
      onContextMenu={shouldShowValue ? handleOnContextMenu : undefined}
    >
      {value !== undefined && shouldShowValue ? (
        <div
          className="relative h-full overflow-hidden"
          style={{ textOverflow: 'ellipsis' }}
          title={displayStringValue}
        >
          <span
            className="absolute inline-block"
            style={{
              maxWidth: `${maxWidth}px`,
              zIndex: 2,
              padding: '5px 5px 0 5px',
              wordBreak: 'break-word'
            }}
          >
            {displayValue}
          </span>
          <span className="absolute cursor-pointer" onClick={handleToggle} style={{ right: '5px', top: '5px' }}>
            <Triangle style={{ width: '10px', height: '10px' }} type={canCollapse ? 'down' : 'right'} />
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default GroupCell
