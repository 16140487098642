import React, { useState, useRef, useEffect } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import Checkbox from 'components/checkbox'
import Editor from 'components/editor'
import {
  ALL_TYPES,
  SELECT_COLUMN_TYPES,
  BLOCK_SELECT_OPTION_TYPES,
  BLOCK_GROUP_BY,
  ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS,
  COLUMN_TYPE_NAMES,
  TEXT_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  IntegrationSyncTypes
} from 'components/spreadsheet/constants/const'
import { IColumnTypes, sortDirections } from 'components/spreadsheet/types'
import { ITable, ITableView, SelectKindOptions, ITableViewColumn, SelectDependencyColumn, EditorContent } from 'types'
import { Cross, Delete, Pencil, Save, Arrow } from 'components/icons'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import 'codemirror/mode/python/python'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/xml-fold'
import 'codemirror/addon/fold/indent-fold'
import 'codemirror/addon/fold/markdown-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/theme/material-darker.css'
import ColumnKindModal from 'components/spreadsheet/components/modal/views/columnkind'
import { isEditorEmpty } from 'components/spreadsheet/helpers/functions'
import { FixedSizeList } from 'react-window'
import { getProjectTables } from 'helpers/utils'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { getFilterValues } from 'components/spreadsheet/helpers/filtering'
import Select from 'components/select'
import constants from 'style/constants.module.scss'
import { CompactPicker } from 'react-color'
import Formula from './components/formula'
import Script from './components/script'
import Flag from 'components/flag'

const EditColumnMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { spreadsheetData, setSpreadsheetData, uniqueNumber } = useDataContext()

  const column = spreadsheetData.viewDetails.columns.find((col) => col.publicId === menuState.columnId)

  const {
    name,
    publicId,
    description,
    kind,
    script,
    scriptEnabled,
    formula,
    formulaEnabled,
    validationMessage,
    stringValidation,
    hardValidation,
    validationNoBlanks,
    validationNoDuplicates,
    isJoined
  } = column || {}

  const [tables, setTables] = useState<ITable[]>()

  const [selectedKind, setSelectedKind] = useState<IColumnTypes>()
  const [processing, setProcessing] = useState<boolean>(false)
  const stringValidationTimeout = useRef<TimeoutID | null>(null)
  const validationMessageTimeout = useRef<TimeoutID | null>(null)
  const nameTimeout = useRef<TimeoutID | null>(null)
  const descriptionTimeout = useRef<TimeoutID | null>(null)
  const dateFormatTimeout = useRef<TimeoutID | null>(null)
  const exportWidthTimeout = useRef<TimeoutID | null>(null)
  const [changeColumnKindModalState, setChangeColumnKindModalState] = useState<boolean>(false)
  const [showDependencyColumn, setShowDependencyColumn] = useState<boolean>(false)
  const [showDependencyJoinColumn, setShowDependencyJoinColumn] = useState<boolean>(false)
  const [canAddDependency, setCanAddDependency] = useState<boolean>(false)
  const [dependencyColumn, setDependencyColumn] = useState<SelectDependencyColumn>({
    columnId: '',
    columnJoinId: ''
  })
  const [regexError, setRegexError] = useState<string>()
  const [validationOptionsOpen, setValidationOptionsOpen] = useState<boolean>(false)
  const [selectOptionsOpen, setSelectOptionsOpen] = useState<boolean>(false)
  const [styleOptionsOpen, setStyleOptionsOpen] = useState<boolean>(false)

  // Select properties
  const { project } = useProject()
  const [selectedTableViews, setSelectedTableViews] = useState<ITableView[]>()
  const [selectedTableViewColumns, setSelectedTableViewColumns] = useState<ITableViewColumn[]>()
  const [manualOption, setManualOption] = useState<string>()
  const [manualOptionSortDirection, setManualOptionSortDirection] = useState<sortDirections>()
  const [editingOption, setEditingOption] = useState<number>()
  const [currentKindOptions, setCurrentKindOptions] = useState<SelectKindOptions | undefined>(
    column ? column.kindOptions : undefined
  )

  // Loaders
  const [noAccess, setNoAccess] = useState<boolean>(false)

  useEffect(() => {
    if (currentKindOptions?.tableOptions) {
      if (currentKindOptions.tableOptions?.tableId) {
        setProcessing(true)
        api
          .getTableViews(currentKindOptions.tableOptions.tableId, { ignoreColumns: true })
          .then((response) => {
            setSelectedTableViews(getViews(response.data))
            if (currentKindOptions.tableOptions?.viewId) {
              api
                .getTableView(currentKindOptions.tableOptions.viewId, { ignoreCachedOptions: true })
                .then((response) => {
                  setSelectedTableViewColumns(response.data.columns)
                  setProcessing(false)
                })
                .catch(() => {
                  setProcessing(false)
                  setSelectedTableViewColumns([])
                  setSelectedTableViewColumns([])
                })
            } else {
              setProcessing(false)
            }
          })
          .catch((error) => {
            if (error.status && error.status === 403) {
              setNoAccess(true)
            }
            setSelectedTableViews([])
            setProcessing(false)
            setSelectedTableViewColumns([])
          })

        if (currentKindOptions.tableOptions?.dependencies && currentKindOptions.tableOptions.dependencies.length > 0) {
          setShowDependencyColumn(true)
        }
      }
    }
    if (currentKindOptions?.manualOptions) {
      const manualOptionsUpperCase = currentKindOptions!.manualOptions.map((item) => item.toUpperCase())
      const manualOptionsAsc = [...manualOptionsUpperCase]
      manualOptionsAsc.sort()
      if (manualOptionsUpperCase.every((element, index) => element === manualOptionsAsc[index])) {
        setManualOptionSortDirection(sortDirections.asc)
      } else {
        const manualOptionsDesc = [...manualOptionsAsc]
        manualOptionsDesc.reverse()
        if (manualOptionsUpperCase.every((element, index) => element === manualOptionsDesc[index])) {
          setManualOptionSortDirection(sortDirections.desc)
        } else {
          setManualOptionSortDirection(undefined)
        }
      }
    }
  }, [menuState.columnId, currentKindOptions])

  useEffect(() => {
    if (menuState.open && project.publicId !== '' && kind && SELECT_COLUMN_TYPES.includes(kind)) {
      getProjectTables(project.publicId)
        .then((tables) => {
          setTables(tables)
        })
        .catch((error) => displayErrorMessage(error))
    }
  }, [open, project.publicId, kind])

  useEffect(() => {
    const columnFound = spreadsheetData.viewDetails.columns.find((col) => col.publicId === menuState.columnId)
    if (kind && SELECT_COLUMN_TYPES.includes(kind) && columnFound?.kindOptions) {
      setCurrentKindOptions(columnFound.kindOptions)
    } else {
      setCurrentKindOptions(undefined)
    }
  }, [
    kind,
    menuState.columnNumber !== undefined &&
      spreadsheetData.viewDetails.columns[menuState.columnNumber] &&
      spreadsheetData.viewDetails.columns[menuState.columnNumber].kindOptions
  ])

  const handleNameChange = (name: string) => {
    if (nameTimeout.current !== null) cancelTimeout(nameTimeout.current)

    nameTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'name',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          name,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleStringValidationChange = (regex: string) => {
    if (stringValidationTimeout.current !== null) cancelTimeout(stringValidationTimeout.current)

    try {
      new RegExp(regex)
      if (regexError) {
        setRegexError(undefined)
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        setRegexError(e.message)
      }
      return
    }

    stringValidationTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'stringValidation',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          regex,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleValidationMessageChange = (message: string) => {
    if (validationMessageTimeout.current !== null) cancelTimeout(validationMessageTimeout.current)

    validationMessageTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'validationMessage',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          message,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleScriptEnabledChange = (checked: boolean) => {
    if (isJoined || !publicId || checked === undefined) return
    updateColumn(
      project.publicId,
      'scriptEnabled',
      spreadsheetData,
      setSpreadsheetData,
      publicId,
      checked,
      handleUpdateColumnSuccess,
      handleUpdateColumnError
    )
  }

  const handleFormulaEnabledChange = (checked: boolean) => {
    if (isJoined || !publicId || checked === undefined) return
    updateColumn(
      project.publicId,
      'formulaEnabled',
      spreadsheetData,
      setSpreadsheetData,
      publicId,
      checked,
      handleUpdateColumnSuccess,
      handleUpdateColumnError
    )
  }

  const handleHardValidationChange = (checked: boolean) => {
    if (publicId && checked !== undefined) {
      updateColumn(
        project.publicId,
        'hardValidation',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const handleDescriptionChange = (description: EditorContent) => {
    if (descriptionTimeout.current !== null) cancelTimeout(descriptionTimeout.current)

    descriptionTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'description',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          isEditorEmpty(description) ? null : description,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleOnDateFormatChange = (dateFormat: string) => {
    if (dateFormatTimeout.current !== null) cancelTimeout(dateFormatTimeout.current)

    dateFormatTimeout.current = requestTimeout(() => {
      if (publicId) {
        updateColumn(
          project.publicId,
          'dateFormat',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          dateFormat,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleExportWidthChange = (width: number) => {
    if (exportWidthTimeout.current !== null) cancelTimeout(exportWidthTimeout.current)

    exportWidthTimeout.current = requestTimeout(() => {
      if (publicId) {
        updateColumn(
          project.publicId,
          'exportWidth',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          width,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleUpdateColumnSuccess = () => {
    setSnackbarMessage({ status: 'success', message: 'The column was updated successfully!' })
    setProcessing(false)
  }

  const handleUpdateColumnError = (error: any) => {
    setProcessing(false)
    setSelectedKind(undefined)
    displayErrorMessage(error)
  }

  const handleOnKindChange = (selectedKind: string) => {
    const newKind = selectedKind as IColumnTypes
    const isGroupedColumn = !!spreadsheetData.userConfiguration.groupSettings.find(
      (group) => group.columnId === publicId
    )

    if (isGroupedColumn && BLOCK_GROUP_BY.includes(newKind)) {
      setSnackbarMessage({ status: 'error', message: `The new column kind ${newKind} cannot be grouped` })
      return
    }
    setSelectedKind(newKind)
    setChangeColumnKindModalState(true)
  }

  const handleOnThousandSeparatorChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'thousandSeparator',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        (error) => {
          handleUpdateColumnError(error)
        }
      )
    }
  }

  const handleOnLinkDisplayChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'displayLink',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        (error) => {
          handleUpdateColumnError(error)
        }
      )
    }
  }

  const handleOnDecimalChange = (decimalPlaces: number) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'decimalPlaces',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        decimalPlaces,
        handleUpdateColumnSuccess,
        (error) => {
          handleUpdateColumnError(error)
        }
      )
    }
  }

  const handleOnTableChange = (tableId: string) => {
    setProcessing(true)
    setSelectedTableViews(undefined)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        cachedOptions: [],
        tableId: tableId,
        viewId: '',
        columnId: '',
        dependencies: [],
        liveValues: false
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    api
      .getTableViews(tableId, { ignoreColumns: true })
      .then((response) => {
        setSelectedTableViews(getViews(response.data))
        setSelectedTableViewColumns([])
        setProcessing(false)
      })
      .catch((error) => {
        displayErrorMessage(error)
        setProcessing(false)
        setSelectedTableViewColumns([])
        setSelectedTableViews([])
      })
  }

  const getViews = (views: ITableView[]) => {
    return views.filter((view) => ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS.includes(view.type))
  }

  const handleOnTableViewChange = (viewId: string) => {
    setSelectedTableViewColumns(undefined)
    setProcessing(true)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        viewId: viewId,
        columnId: '',
        dependencies: []
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    api
      .getTableView(viewId)
      .then((response) => {
        const view = response.data
        setSelectedTableViewColumns(view.columns)
        setProcessing(false)
      })
      .catch(() => {
        setProcessing(false)
        setSelectedTableViewColumns([])
      })
  }

  const handleOnTableViewColumnChange = (columnId: string) => {
    const columnKind = selectedTableViewColumns!.find((column) => column.publicId === columnId)!.kind
    if (BLOCK_SELECT_OPTION_TYPES.includes(columnKind)) {
      setCurrentKindOptions({
        ...currentKindOptions!,
        tableOptions: {
          ...currentKindOptions!.tableOptions,
          columnId: '',
          dependencies: []
        }
      })
      setCanAddDependency(false)
      setDependencyColumn({ columnId: '', columnJoinId: '' })
      setSnackbarMessage({
        status: 'error',
        message: `${kind} options are not applicable to table columns of type ${columnKind}`
      })
      return
    }
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: { ...currentKindOptions!.tableOptions, columnId: columnId }
    }
    setProcessing(true)
    setCurrentKindOptions(newCurrentKindOptions)
    handleUpdateKindOptions(newCurrentKindOptions, (error) => {
      setCurrentKindOptions({
        ...currentKindOptions!,
        tableOptions: {
          ...currentKindOptions!.tableOptions,
          columnId: '',
          dependencies: []
        }
      })
      handleUpdateColumnError(error)
    })
  }

  const handleOnColumnDependencyChange = (columnId: string) => {
    const foundColumn = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setDependencyColumn({ ...dependencyColumn, columnId: '', columnJoinId: '' })
      setShowDependencyJoinColumn(true)
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable as dependencies`
      })
      return
    }
    if (foundColumn && SELECT_COLUMN_TYPES.includes(foundColumn.kind)) {
      const needsJoin =
        foundColumn.kindOptions?.tableOptions?.tableId !== tableId ||
        foundColumn.kindOptions?.tableOptions?.viewId !== viewId
      setShowDependencyJoinColumn(needsJoin)
      setCanAddDependency(!needsJoin)
    } else {
      setShowDependencyJoinColumn(true)
      setCanAddDependency(false)
    }

    setDependencyColumn({ ...dependencyColumn, columnId: columnId })
  }

  const handleOnColumnDependencyJoinChange = (columnId: string) => {
    if (columnId === currentKindOptions?.tableOptions?.columnId) {
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `Column has been already selected as table options`
      })
      return
    }
    const foundColumn =
      selectedTableViewColumns && selectedTableViewColumns.find((column) => column.publicId === columnId)
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable as dependencies`
      })
      return
    }

    setDependencyColumn({ ...dependencyColumn, columnJoinId: columnId })
    setCanAddDependency(true)
  }

  const handleAddDependencyColumn = () => {
    if (!canAddDependency) return

    const newDependencies = currentKindOptions!.tableOptions?.dependencies
      ? [...currentKindOptions!.tableOptions.dependencies]
      : []

    newDependencies.push(dependencyColumn)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        dependencies: newDependencies
      }
    }
    setCanAddDependency(false)
    setShowDependencyJoinColumn(false)
    handleUpdateKindOptions(newCurrentKindOptions, () => {
      handleUpdateColumnError('Adding the column dependency failed')
    })
    setDependencyColumn({ columnId: '', columnJoinId: '' })
  }

  const handleRemoveTableOptions = () => {
    if (currentKindOptions && currentKindOptions.tableOptions) {
      const newCurrentKindOptions = {
        ...currentKindOptions,
        tableOptions: {
          ...currentKindOptions.tableOptions,
          tableId: '',
          viewId: '',
          columnId: '',
          dependencies: []
        }
      }
      setCurrentKindOptions(newCurrentKindOptions)
      handleUpdateKindOptions(newCurrentKindOptions, handleUpdateColumnError)
    }
  }

  const handleRemoveTableDependency = (index: number) => {
    const newDependencies = [...currentKindOptions!.tableOptions!.dependencies]
    newDependencies.splice(index, 1)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        dependencies: newDependencies
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    handleUpdateKindOptions(newCurrentKindOptions, handleUpdateColumnError)
    if (newDependencies.length === 0) {
      setShowDependencyColumn(false)
    }
  }

  const handleSetLiveValues = (liveValues: boolean) => {
    if (processing || !currentKindOptions) return
    handleUpdateKindOptions(
      {
        ...currentKindOptions,
        tableOptions: {
          ...currentKindOptions.tableOptions,
          liveValues
        }
      },
      handleUpdateColumnError
    )
  }

  const handleAddManualOption = () => {
    if (processing) return
    const newManualOptions = currentKindOptions?.manualOptions ? [...currentKindOptions!.manualOptions] : []
    if (getOptions().includes(manualOption!)) {
      setSnackbarMessage({
        status: 'error',
        message: `Option ${manualOption} already defined.`
      })
      setManualOption('')
      return
    }
    handleUpdateKindOptions(
      {
        ...currentKindOptions!,
        manualOptions: [...newManualOptions, manualOption!]
      },
      handleUpdateColumnError
    )
  }

  const handleEditManualOption = (index: number, value: string) => {
    if (processing) return
    if (currentKindOptions!.manualOptions[index] === value) {
      setEditingOption(undefined)
      return
    }

    if (currentKindOptions!.manualOptions[index] !== value && currentKindOptions!.manualOptions.includes(value)) {
      setSnackbarMessage({ status: 'error', message: 'Option already exists in current options list' })
      return
    }
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions[index] = value

    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setEditingOption(undefined)
  }

  const handleRemoveManualOption = (index: number) => {
    if (processing) return

    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.splice(index, 1)
    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
  }

  const handleSortManualOption = (currentIndex: number, newIndex: number) => {
    if (processing) return
    const manualOption = currentKindOptions!.manualOptions[currentIndex]
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.splice(currentIndex, 1)
    newManualOptions.splice(newIndex, 0, manualOption)
    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setManualOptionSortDirection(undefined)
  }

  const handleChangeSortDirectionManualOption = (direction: sortDirections) => {
    if (processing || direction === manualOptionSortDirection) return
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.sort()
    if (direction === sortDirections.desc) {
      newManualOptions.reverse()
    }
    setManualOptionSortDirection(direction)

    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setEditingOption(undefined)
  }

  const handleUpdateKindOptions = (kindOptions: SelectKindOptions, onError: (error?: string) => void) => {
    setProcessing(true)
    const newKindOptions = JSON.parse(JSON.stringify(kindOptions))
    if (newKindOptions?.tableOptions) {
      delete newKindOptions.tableOptions.cachedOptions
    }
    if (publicId) {
      updateColumn(
        project.publicId,
        'kindOptions',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        newKindOptions,
        handleUpdateColumnSuccess,
        onError
      )
    }

    setManualOption('')
  }

  const handleNoBlanksChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'validationNoBlanks',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const handleNoDuplicatesChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'validationNoDuplicates',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const getOptions = () => {
    let allOptions: string[] = []
    if (currentKindOptions?.manualOptions && currentKindOptions.manualOptions.length > 0) {
      allOptions = allOptions.concat(currentKindOptions?.manualOptions)
    }
    if (
      currentKindOptions?.tableOptions?.cachedOptions &&
      currentKindOptions.tableOptions.cachedOptions.length > 0 &&
      Array.isArray(currentKindOptions.tableOptions.cachedOptions)
    ) {
      allOptions = allOptions.concat(currentKindOptions.tableOptions.cachedOptions)
    }
    return allOptions
  }

  const generateManualOptions = () => {
    if (!column || processing) return
    const newManualOptions = currentKindOptions?.manualOptions ? [...currentKindOptions!.manualOptions] : []
    const values = new Set()

    getFilterValues(spreadsheetData.rows, column, column.publicId).forEach(values.add, values)
    getFilterValues(spreadsheetData.filteredRows, column, column.publicId).forEach(values.add, values)
    const data = Array.from(values)
    const uniqueValues = data.map((dataValue: any) => {
      return dataValue ? dataValue.toString() : ''
    })

    handleUpdateKindOptions(
      {
        ...currentKindOptions!,
        manualOptions: [...newManualOptions, ...uniqueValues]
      },
      handleUpdateColumnError
    )
  }

  const allOptions = getOptions()
  const tableId = currentKindOptions?.tableOptions?.tableId
  const viewId = currentKindOptions?.tableOptions?.viewId
  const viewColumnId = currentKindOptions?.tableOptions?.columnId
  const dependencies = currentKindOptions?.tableOptions?.dependencies || []
  const selectedTableName = tables ? tables.find((table: ITable) => table.publicId === tableId)?.name : undefined
  const selectedViewName = selectedTableViews
    ? selectedTableViews.find((view) => view.publicId === viewId)?.name
    : undefined

  const disabled = isJoined || processing
  const isSynced =
    column &&
    (column.viewpointSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.viewpointRfisSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.autodeskBim360Synced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.autodeskBim360ChecklistsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.procoreSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.mortaSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.reviztoIssuesSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.asiteDocumentsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.aconexWorkflowsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.aconexSynced !== IntegrationSyncTypes.NOT_SYNCED)
  const disableSelectConfiguration = isJoined || isSynced
  const maxHeight = document.getElementById(`data-view-${uniqueNumber}`)?.clientHeight || 550
  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div className="overflow-y-auto" style={{ maxHeight: maxHeight }}>
        <div style={{ padding: '15px' }}>
          <label htmlFor="column-name" className="block text-sm font-bold mb-2">
            Name
          </label>
          <input
            id="column-name"
            className="text-sm"
            type="text"
            defaultValue={name}
            readOnly={isSynced}
            onChange={(event) => handleNameChange(event.target.value)}
          />
        </div>

        <div style={{ padding: '15px' }}>
          <label className="block text-sm font-bold mb-2">Description</label>
          <Editor
            databaseDoc={description}
            readOnly={false}
            editorId={publicId ? publicId : ''}
            border={true}
            onChange={handleDescriptionChange}
          />
        </div>

        <div style={{ padding: '15px' }}>
          <label className="block text-sm font-bold mb-2">Type</label>
          <div className="w-full mb-4">
            <Select
              options={ALL_TYPES.sort((typeA, typeB) =>
                COLUMN_TYPE_NAMES[typeA] > COLUMN_TYPE_NAMES[typeB]
                  ? 1
                  : COLUMN_TYPE_NAMES[typeA] < COLUMN_TYPE_NAMES[typeB]
                  ? -1
                  : 0
              ).map((type) => {
                return { label: COLUMN_TYPE_NAMES[type], value: type as string }
              })}
              disabled={disabled || isSynced}
              optionsSelected={column ? [column.kind] : []}
              onOptionClick={(option) => handleOnKindChange(option)}
            />
          </div>
        </div>

        {!disableSelectConfiguration &&
          ((selectedKind && SELECT_COLUMN_TYPES.includes(selectedKind)) ||
            (kind && SELECT_COLUMN_TYPES.includes(kind))) && (
            <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
              <div className="font-bold">Select Options</div>
              <Button
                className="ml-auto"
                internalType={selectOptionsOpen ? 'danger' : 'accept'}
                style={{ width: '100px' }}
                onClick={() => {
                  setSelectOptionsOpen(!selectOptionsOpen)
                }}
              >
                {selectOptionsOpen ? 'Hide' : 'Show'}
              </Button>
            </div>
          )}
        {selectOptionsOpen && (
          <div className="mb-4">
            <div style={{ padding: '15px' }}>
              <label className="block text-sm font-bold mb-2">Source Table</label>
              <Select
                options={
                  tables
                    ? tables.map((table) => {
                        return { label: table.name, value: table.publicId }
                      })
                    : []
                }
                loading={tables === undefined ? true : false}
                optionsSelected={tableId ? [tableId] : []}
                onOptionClick={(option) => handleOnTableChange(option)}
                info={'This is the table containing the source column.'}
              />
            </div>

            <div style={{ padding: '15px' }}>
              <label className="block text-sm font-bold mb-2">Source View</label>
              <Select
                options={
                  selectedTableViews
                    ? selectedTableViews.map((view) => {
                        return { label: view.name, value: view.publicId }
                      })
                    : []
                }
                onOptionClick={(option) => handleOnTableViewChange(option)}
                optionsSelected={viewId ? [viewId] : []}
                disabled={!tableId}
                loading={tableId && !selectedTableViews ? true : false}
                info={'A table must be selected before you can select the view.'}
              />
            </div>

            <div style={{ padding: '15px' }}>
              <label className="block text-sm font-bold mb-2">Source Column</label>
              <Select
                options={
                  selectedTableViewColumns
                    ? selectedTableViewColumns.map((column) => {
                        return { label: column.name, value: column.publicId }
                      })
                    : []
                }
                onOptionClick={(option) => handleOnTableViewColumnChange(option)}
                optionsSelected={viewColumnId ? [viewColumnId] : []}
                disabled={!viewId}
                loading={viewId && selectedTableViewColumns === undefined ? true : false}
                info={'A table and view must be selected before you can select the column.'}
              />
            </div>
            {noAccess && (
              <p style={{ marginTop: '20px', marginBottom: '20px', color: 'red' }}>
                You do not have access to the table or view that has been selected by another admin of this table.
                {tableId && viewId && (
                  <span>
                    <a
                      href={`/project/${project.publicId}/table/${tableId}/view/${viewId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to the table
                    </a>{' '}
                    to request access.
                  </span>
                )}
              </p>
            )}
            {currentKindOptions && currentKindOptions.tableOptions && currentKindOptions.tableOptions.tableId && (
              <div style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">Reset Options</label>
                <Button internalType="danger" onClick={() => handleRemoveTableOptions()}>
                  Reset Source Column Options (Table, View and Column)
                </Button>
              </div>
            )}

            <div style={{ padding: '15px' }}>
              <label className="block text-sm font-bold mb-2">Live Values</label>
              <Checkbox
                checked={currentKindOptions?.tableOptions.liveValues}
                onChange={(event) => handleSetLiveValues(event.target.checked)}
              >
                Keep Values Up To Date
              </Checkbox>
              <label className="text-secondary" style={{ whiteSpace: 'break-spaces' }}>
                Non-blank values are automatically updated from the source table. Make sure the source values are unique
                to avoid unintended updates. Emptying a value in the source table will result in a validation warning,
                wherever that value is selected.
              </label>
            </div>
            {dependencies.length === 0 && viewColumnId && !showDependencyColumn && (
              <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
                <div className="font-bold">Dependency Columns</div>
                <Button
                  className="ml-auto"
                  style={{ width: '100px' }}
                  internalType="accept"
                  onClick={() => setShowDependencyColumn(true)}
                >
                  Add
                </Button>
              </div>
            )}
            {viewColumnId && showDependencyColumn && (
              <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
                <div className="font-bold">Dependency Columns</div>
              </div>
            )}
            {viewColumnId && showDependencyColumn && dependencies.length > 0 && (
              <div className="grid" style={{ padding: '15px', justifyItems: 'start' }}>
                {dependencies.map((dependency, index) => {
                  const foundCurrentColumn = spreadsheetData.viewDetails.columns.find(
                    (col) => col.publicId === dependency.columnId
                  )
                  const columnJoinId = dependency.columnJoinId
                    ? dependency.columnJoinId
                    : foundCurrentColumn?.kindOptions?.tableOptions?.columnId

                  const columnMatchName = selectedTableViewColumns?.find((col) => col.publicId === columnJoinId)?.name
                  const remoteMatch = `${selectedTableName}/${selectedViewName}/${columnMatchName}`

                  return (
                    <div
                      key={`column-select-${index}`}
                      className="inline-flex items-center space-between"
                      style={{ padding: '5px 0' }}
                    >
                      {selectedViewName && columnMatchName && foundCurrentColumn?.name ? (
                        <div className="inline-flex items-center w-full space-evenly">
                          <label style={{ whiteSpace: 'break-spaces' }}>
                            {spreadsheetData.tableDetails.name}/{spreadsheetData.viewDetails.name}/
                            {foundCurrentColumn?.name} Match to {remoteMatch}
                          </label>

                          <div onClick={() => handleRemoveTableDependency(index)}>
                            <Delete style={{ height: '24px', width: '24px' }} />
                          </div>
                        </div>
                      ) : (
                        <div className="spin" style={{ height: '16px', width: '16px' }} />
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            {viewColumnId && showDependencyColumn && (
              <div style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">
                  {spreadsheetData.tableDetails.name}/{spreadsheetData.viewDetails.name}
                </label>

                <Select
                  options={spreadsheetData.viewDetails.columns.map((column) => {
                    return { label: column.name, value: column.publicId }
                  })}
                  onOptionClick={(option) => handleOnColumnDependencyChange(option)}
                  optionsSelected={dependencyColumn.columnId ? [dependencyColumn.columnId] : []}
                />

                {showDependencyJoinColumn && (
                  <label className="block text-sm font-bold mb-2" style={{ padding: '15px 0' }}>
                    Match to {selectedTableName}/{selectedViewName}
                  </label>
                )}

                {showDependencyJoinColumn && (
                  <Select
                    options={
                      selectedTableViewColumns
                        ? selectedTableViewColumns.map((column) => {
                            return { label: column.name, value: column.publicId }
                          })
                        : []
                    }
                    onOptionClick={(option) => handleOnColumnDependencyJoinChange(option)}
                    optionsSelected={dependencyColumn.columnJoinId ? [dependencyColumn.columnJoinId] : []}
                  />
                )}
                {canAddDependency && (
                  <label className="block text-sm mb-2" style={{ whiteSpace: 'break-spaces', padding: '15px 0' }}>
                    {
                      spreadsheetData.viewDetails.columns.find(
                        (column) => column.publicId === dependencyColumn?.columnId
                      )!.name
                    }{' '}
                    {dependencyColumn?.columnJoinId ? (
                      <span>
                        needs to be linked to a column in {selectedTableName}/{selectedViewName}
                      </span>
                    ) : (
                      <span>
                        has already a link to {selectedTableName}/{selectedViewName}.
                      </span>
                    )}
                  </label>
                )}
                <Button
                  internalType="accept"
                  style={{ width: 50, margin: '5px 10px' }}
                  disabled={!canAddDependency}
                  onClick={() => handleAddDependencyColumn()}
                >
                  Add
                </Button>
              </div>
            )}
            <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
              <div className="font-bold">Manual Options</div>
            </div>

            <div className="flex" style={{ padding: '15px' }}>
              <Button className="ml-auto" internalType="primary" onClick={() => generateManualOptions()}>
                + Add Unique Column Values
              </Button>
              {currentKindOptions?.manualOptions && currentKindOptions?.manualOptions.length > 0 && (
                <div
                  className="flex items-center bg-light-grey rounded"
                  style={{ marginLeft: '15px', width: '160px', padding: '5px' }}
                >
                  <div
                    className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                        ${manualOptionSortDirection === sortDirections.asc ? 'bg-grey' : ''}`}
                    style={{ marginRight: '10px', padding: '5px', flex: 1 }}
                    onClick={() => {
                      handleChangeSortDirectionManualOption(sortDirections.asc)
                    }}
                  >
                    {`A → Z`}
                  </div>
                  <div
                    className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                          ${manualOptionSortDirection === sortDirections.desc ? 'bg-grey' : ''}`}
                    style={{ padding: '5px', flex: 1 }}
                    onClick={() => {
                      handleChangeSortDirectionManualOption(sortDirections.desc)
                    }}
                  >
                    {`Z → A`}
                  </div>
                </div>
              )}
            </div>

            <div className="flex" style={{ padding: '15px' }}>
              <input
                type="text"
                placeholder={'Enter manual option'}
                value={manualOption}
                onChange={(event) => setManualOption(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleAddManualOption()
                }}
              />
              <Button
                internalType="accept"
                onClick={() => handleAddManualOption()}
                style={{ marginLeft: 5, width: 100 }}
                disabled={!!!manualOption}
              >
                Add
              </Button>
            </div>
            <div style={{ padding: '15px' }}>
              <label className="block text-sm font-bold mb-2">Options List Preview</label>
              <div className="rounded border-1px border-solid border-black" style={{ padding: '5px', height: '160px' }}>
                {allOptions.length === 0 ? (
                  <span>No options available</span>
                ) : (
                  <FixedSizeList height={150} itemCount={allOptions.length} itemSize={35} width={'100%'}>
                    {({ index, style }) => {
                      const [newValue, setNewValue] = useState<string>(allOptions[index])
                      const option = allOptions[index]
                      const isManualOption =
                        currentKindOptions?.manualOptions && currentKindOptions.manualOptions.length > 0
                          ? currentKindOptions.manualOptions.includes(option)
                          : false
                      const editing = editingOption === index
                      const disableAction = processing || editingOption !== undefined
                      return (
                        <div
                          key={`${index}-${option}`}
                          className={`flex ${isManualOption ? '' : 'bg-light-green'}`}
                          style={style}
                        >
                          {editing ? (
                            <input
                              value={newValue}
                              onChange={(event) => setNewValue(event.target.value)}
                              className="edit-manual-option-input"
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleEditManualOption(index, newValue)
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <span className="truncate" style={{ width: 'calc(100% - 30px)' }}>
                              {option}
                            </span>
                          )}
                          {isManualOption && (
                            <div className="flex items-center">
                              <span
                                style={{
                                  cursor: disableAction ? 'auto' : 'pointer',
                                  display: 'inline-flex'
                                }}
                              >
                                {editingOption === undefined && (
                                  <div>
                                    {index > 0 && (
                                      <span
                                        className="flex items-center"
                                        style={{ color: processing ? 'disabled' : 'inherit' }}
                                        onClick={() => handleSortManualOption(index, index - 1)}
                                      >
                                        <Arrow type="up" />
                                      </span>
                                    )}
                                    {index < currentKindOptions!.manualOptions?.length - 1 && (
                                      <span
                                        className="flex items-center"
                                        style={{ color: processing ? 'disabled' : 'inherit' }}
                                        onClick={() => handleSortManualOption(index, index + 1)}
                                      >
                                        <Arrow type="down" />
                                      </span>
                                    )}
                                  </div>
                                )}

                                {editing ? (
                                  <span
                                    className="flex items-center"
                                    style={{ color: processing ? 'disabled' : 'inherit' }}
                                  >
                                    <span onClick={() => handleEditManualOption(index, newValue)}>
                                      <Save />
                                    </span>
                                    <span onClick={() => setEditingOption(undefined)}>
                                      <Cross />
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    className="flex items-center"
                                    style={{ color: processing ? 'disabled' : 'inherit' }}
                                    onClick={() => setEditingOption(index)}
                                  >
                                    <Pencil style={{ height: '24px', width: '24px' }} />
                                  </span>
                                )}
                              </span>
                              <span
                                style={{
                                  cursor: disableAction && editing ? 'auto' : 'pointer',
                                  color: disableAction && editing ? 'disabled' : 'inherit'
                                }}
                                onClick={() => handleRemoveManualOption(index)}
                              >
                                <Delete style={{ height: '24px', width: '24px' }} />
                              </span>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  </FixedSizeList>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
          <div className="font-bold">Validation</div>
          <Button
            className="ml-auto"
            internalType={validationOptionsOpen ? 'danger' : 'accept'}
            style={{ width: '100px' }}
            onClick={() => {
              setValidationOptionsOpen(!validationOptionsOpen)
            }}
          >
            {validationOptionsOpen ? 'Hide' : 'Show'}
          </Button>
        </div>

        {validationOptionsOpen && (
          <div className="mb-4">
            {kind && TEXT_COLUMN_TYPES.includes(kind) && (
              <div style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">Text Validation (Regex)</label>
                <input
                  type="text"
                  defaultValue={stringValidation ? stringValidation : ''}
                  onChange={(event) => handleStringValidationChange(event.target.value)}
                />
                <br />
                {regexError ? <div style={{ color: 'red', marginTop: '5px' }}>{regexError}</div> : null}
              </div>
            )}

            <div style={{ padding: '5px 15px' }}>
              <label className="block text-sm font-bold mb-2">Validation Message</label>
              <input
                type="text"
                defaultValue={validationMessage ? validationMessage : ''}
                onChange={(event) => handleValidationMessageChange(event.target.value)}
              />
            </div>

            <div style={{ padding: '5px 15px' }}>
              <select
                defaultValue={validationNoDuplicates ? 'block' : 'allow'}
                onChange={(event) => {
                  if (event.target.value === 'allow') {
                    handleNoDuplicatesChange(false)
                  } else {
                    handleNoDuplicatesChange(true)
                  }
                }}
              >
                <option value="allow">Allow Duplicate Values</option>
                <option value="block">Block Duplicate Values</option>
              </select>
            </div>

            <div style={{ padding: '5px 15px' }}>
              <select
                defaultValue={validationNoBlanks ? 'block' : 'allow'}
                onChange={(event) => {
                  if (event.target.value === 'allow') {
                    handleNoBlanksChange(false)
                  } else {
                    handleNoBlanksChange(true)
                  }
                }}
              >
                <option value="allow">Allow Blank Values</option>
                <option value="block">Block Blank Values</option>
              </select>
            </div>

            <div style={{ padding: '5px 15px' }}>
              <select
                defaultValue={hardValidation ? 'block' : 'allow'}
                onChange={(event) => {
                  if (event.target.value === 'allow') {
                    handleHardValidationChange(false)
                  } else {
                    handleHardValidationChange(true)
                  }
                }}
              >
                <option value="allow">Allow Data Entry</option>
                <option value="block">Block Data Entry</option>
              </select>
            </div>
          </div>
        )}

        <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
          <div className="font-bold">Formatting</div>
          <Button
            className="ml-auto"
            internalType={styleOptionsOpen ? 'danger' : 'accept'}
            style={{ width: '100px' }}
            onClick={() => {
              setStyleOptionsOpen(!styleOptionsOpen)
            }}
          >
            {styleOptionsOpen ? 'Hide' : 'Show'}
          </Button>
        </div>

        {styleOptionsOpen && (
          <div className="mb-4">
            {column &&
              column.kind &&
              (column.kind === 'integer' ||
                column.kind === 'float' ||
                column.kind === 'duration' ||
                column.kind === 'percentage') && (
                <div className="w-full" style={{ padding: '15px' }}>
                  <label className="block text-sm font-bold mb-2">Number Formatting</label>
                  <select
                    className="text-sm"
                    defaultValue={column.thousandSeparator ? 'Comma' : 'None'}
                    onChange={(event) => {
                      if (event.target.value === 'None') {
                        handleOnThousandSeparatorChange(false)
                      } else {
                        handleOnThousandSeparatorChange(true)
                      }
                    }}
                  >
                    <option value="Comma">Comma (1,000)</option>
                    <option value="None">None (1000)</option>
                  </select>
                </div>
              )}

            {column && column.kind === 'link' && (
              <div className="w-full" style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">URL Formatting</label>
                <select
                  className="text-sm"
                  defaultValue={column.displayLink ? 'text' : 'icon'}
                  onChange={(event) => {
                    if (event.target.value === 'text') {
                      handleOnLinkDisplayChange(true)
                    } else {
                      handleOnLinkDisplayChange(false)
                    }
                  }}
                >
                  <option value="text">Full Link</option>
                  <option value="icon">Link Icon</option>
                </select>
              </div>
            )}

            {column && column.kind && (column.kind === 'float' || column.kind === 'percentage') && (
              <div className="w-full" style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">Decimal Places</label>
                <input
                  className="text-sm"
                  type="number"
                  defaultValue={column ? column.decimalPlaces : 2}
                  onChange={(event) => handleOnDecimalChange(parseInt(event.target.value))}
                  min={0}
                  max={10}
                />
              </div>
            )}

            {column && column.kind && DATE_COLUMN_TYPES.includes(column.kind) && (
              <div className="w-full" style={{ padding: '15px' }}>
                <label className="block text-sm font-bold mb-2">Date Format</label>
                <input
                  type="text"
                  className="text-sm"
                  defaultValue={column && column.dateFormat ? column.dateFormat : ''}
                  placeholder={column && column.kind === 'datetime' ? 'e.g. YYYY-MM-DD HH:mm:ss' : 'e.g. YYYY-MM-DD'}
                  style={{ marginRight: '10px' }}
                  onChange={(event) => handleOnDateFormatChange(event.target.value)}
                />
              </div>
            )}

            <div style={{ padding: '15px' }}>
              <label htmlFor="column-name" className="block text-sm font-bold mb-2">
                Column Header Background Colour
              </label>

              {column && column.headerBackgroundColor && (
                <CompactPicker
                  color={column.headerBackgroundColor}
                  onChange={(value: any) =>
                    updateColumn(
                      project.publicId,
                      'headerBackgroundColor',
                      spreadsheetData,
                      setSpreadsheetData,
                      column.publicId,
                      value.hex,
                      handleUpdateColumnSuccess,
                      handleUpdateColumnError
                    )
                  }
                />
              )}
            </div>

            <div style={{ padding: '15px' }}>
              <label htmlFor="column-name" className="block text-sm font-bold mb-2">
                Column Header Text Colour
              </label>

              {column && column.headerTextColor && (
                <CompactPicker
                  color={column.headerTextColor}
                  onChange={(value: any) =>
                    updateColumn(
                      project.publicId,
                      'headerTextColor',
                      spreadsheetData,
                      setSpreadsheetData,
                      column.publicId,
                      value.hex,
                      handleUpdateColumnSuccess,
                      handleUpdateColumnError
                    )
                  }
                />
              )}
            </div>

            <div className="flex flex-column w-full" style={{ padding: '15px' }}>
              <label htmlFor="column-name" className="block text-sm font-bold mb-2">
                Column Header Export Width (cm)
              </label>
              <select
                value={column && column.exportWidth !== null ? 'manual' : 'auto'}
                onChange={(event) => {
                  if (column) {
                    updateColumn(
                      project.publicId,
                      'exportWidth',
                      spreadsheetData,
                      setSpreadsheetData,
                      column.publicId,
                      event.target.value === 'auto' ? null : 5,
                      handleUpdateColumnSuccess,
                      handleUpdateColumnError
                    )
                  }
                }}
              >
                <option value="auto">Auto</option>
                <option value="manual">Manual</option>
              </select>
              {column && column.exportWidth !== null && (
                <input
                  style={{ marginTop: '5px' }}
                  type="number"
                  min={0}
                  defaultValue={column.exportWidth}
                  onChange={(event) => handleExportWidthChange(Number.parseInt(event.target.value))}
                />
              )}
            </div>
          </div>
        )}

        <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
          <div className="flex items-center font-bold">
            Formula
            <Flag text="Beta" color={constants.red} />
          </div>
          <Button
            className="ml-auto"
            internalType={formulaEnabled ? 'danger' : 'accept'}
            style={{ width: '100px' }}
            onClick={() => {
              if (formulaEnabled) {
                handleFormulaEnabledChange(false)
              } else {
                window.confirm(
                  'Are you sure you want to enable formulas on this column? This will remove existing data.'
                ) && handleFormulaEnabledChange(true)
              }
            }}
            disabled={isSynced}
          >
            {formulaEnabled ? 'Disable' : 'Enable'}
          </Button>
        </div>

        {column && formulaEnabled && (
          <Formula columnPublicId={column.publicId} formula={formula ? formula : ''} setEditMenu={setMenuState} />
        )}

        <div className="flex items-center text-base w-full bg-light-grey" style={{ padding: '15px' }}>
          <div className="font-bold">Script</div>
          <Button
            className="ml-auto"
            internalType={scriptEnabled ? 'danger' : 'accept'}
            style={{ width: '100px' }}
            onClick={() => {
              handleScriptEnabledChange(!scriptEnabled)
            }}
            disabled={isSynced}
          >
            {scriptEnabled ? 'Disable' : 'Enable'}
          </Button>
        </div>

        {column && scriptEnabled && (
          <Script columnPublicId={column.publicId} script={script} handleUpdateColumnError={handleUpdateColumnError} />
        )}

        {changeColumnKindModalState && selectedKind && (
          <ColumnKindModal
            id="change-column-kind-modal"
            open={changeColumnKindModalState}
            setOpen={setChangeColumnKindModalState}
            newKind={selectedKind}
            columnId={publicId}
            onCancel={() => {
              if (column) {
                setSelectedKind(column.kind)
              }
            }}
            zIndex={3001}
          />
        )}
      </div>
    </Menu>
  )
}

export default EditColumnMenu
