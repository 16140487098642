import React from 'react'
import Permissions from 'components/permissions'
import { AccessResourceKinds, UserRoles } from 'app-constants'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Modal, { ModalProps } from 'components/modal'

const PermissionsModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { spreadsheetData } = useDataContext()
  if (!spreadsheetData.loading && !spreadsheetData.streaming) {
    return (
      <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title={'Permissions'}>
        <div
          className="overflow-x-hidden"
          style={{ listStyle: 'none', height: '500px', padding: '10px', margin: '10px' }}
        >
          <div>
            <div
              className="border-b-1px border-solid border-grey font-bold text-base"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              Table Permissions
            </div>
            <Permissions
              resource={AccessResourceKinds.TABLE}
              resourceId={spreadsheetData.tableDetails.publicId}
              excludedPermissionCategories={[UserRoles.VIEWER, UserRoles.CONTRIBUTER]}
            />
          </div>
          <div>
            <div
              className="border-b-1px border-solid border-grey font-bold text-base"
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              View Permissions
            </div>
            <Permissions
              resource={AccessResourceKinds.VIEW}
              resourceId={spreadsheetData.viewDetails.publicId}
              excludedPermissionCategories={[UserRoles.OWNER]}
            />
          </div>
        </div>
      </Modal>
    )
  } else {
    return <div />
  }
}

export default PermissionsModal
