import React from 'react'
import Permissions from 'components/permissions'
import { AccessResourceKinds } from 'app-constants'
import { useDataContext } from 'components/process/contexts/data'
import Modal, { ModalProps } from 'components/modal'

const PermissionsModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { process } = useDataContext()
  if (process) {
    return (
      <Modal id={id} open={open} setOpen={setOpen} title="Permissions" onClose={onClose}>
        <div
          className="overflow-hidden"
          style={{ listStyle: 'none', height: '500px', padding: '10px', margin: '10px' }}
        >
          <Permissions resource={AccessResourceKinds.PROCESS} resourceId={process.publicId} />
        </div>
      </Modal>
    )
  } else {
    return <div />
  }
}

export default PermissionsModal
