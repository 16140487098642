import React, { useEffect, useState } from 'react'
import { OptionProps } from 'types'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { getFilterValues } from 'components/spreadsheet/helpers/filtering'
import Select from 'components/select'

// This is the expected type of value returned from the API for field values
export type FilterValueType = string | number | boolean | null

interface Props {
  columnId: string
  multipleValues: string[]
  onChange: (options: string[]) => void
  optionsOpen?: boolean
}

// Construct the option label from it's value
const createLabel = (value: FilterValueType): string => {
  if (value === undefined || value === null) {
    return ''
  } else {
    return value.toString()
  }
}

// For filter, this will fetch unique values from the backend for the column the
// filter is assigned to, and present that in a multi select dropdown UI component
const FieldValueMultiPickerInput: React.FC<Props> = ({ columnId, multipleValues, onChange, optionsOpen }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [options, setOptions] = useState<OptionProps[]>([])
  const { spreadsheetData } = useDataContext()

  useEffect(() => {
    buildOptions()
  }, [columnId])

  const buildOptions = () => {
    const values = new Set()
    const column = spreadsheetData.viewDetails.columns.find((c) => c.publicId == columnId)!

    getFilterValues(spreadsheetData.rows, column, columnId).forEach(values.add, values)
    getFilterValues(spreadsheetData.filteredRows, column, columnId).forEach(values.add, values)
    const data = Array.from(values)
    const selectOptions: OptionProps[] = data.map((dataValue: any) => {
      return {
        value: dataValue !== null && dataValue !== undefined ? dataValue.toString() : '',
        label: createLabel(dataValue)
      }
    })
    selectOptions.sort((a, b) => (a.label < b.label ? -1 : 1))
    if (spreadsheetData.isAdmin) {
      selectOptions.push({ value: '{{user.email}}', label: '{{user.email}}' })
      selectOptions.push({ value: '{{user.tags}}', label: '{{user.tags}}' })
      selectOptions.push({ value: '{{user.tag_values}}', label: '{{user.tag_values}}' })
      selectOptions.push({ value: '{{process.variables}}', label: '{{process.variables}}' })
      selectOptions.push({ value: '{{process.variable_values}}', label: '{{process.variable_values}}' })
    }

    setOptions(selectOptions)
    setIsLoaded(true)
  }

  return (
    <Select
      options={options}
      optionsSelected={multipleValues}
      onOptionClick={(option) => {
        const newOptions = [...multipleValues]
        const indexOf = newOptions.findIndex((value) => value === option)
        if (indexOf === -1) {
          newOptions.push(option)
        } else {
          newOptions.splice(indexOf, 1)
        }
        onChange(newOptions)
      }}
      multiselect={true}
      loading={!isLoaded}
      defaultOpen={optionsOpen}
    />
  )
}

export default FieldValueMultiPickerInput
