import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import {
  Copy,
  Delete,
  ExpandAll,
  Plus,
  Restore,
  Template,
  Comment,
  Variable,
  Audit,
  Permissions,
  Filter
} from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { useDataContext } from 'components/process/contexts/data'
import ResourceType from 'components/resourceType'
import Toggle from 'components/toggle'
import api from 'helpers/api'
import useProject from 'hooks/project'

interface SettingsMenuProps extends MenuProps {
  setExportModal: (open: boolean) => void
  addSection: (parentId: string | null) => void
  setRestoreSectionModal: (open: boolean) => void
  setDuplicateModal: (open: boolean) => void
  setArchiveModal: (open: boolean) => void
  setAuditModal: (open: boolean) => void
  setPermissionsModal: (open: boolean) => void
  setDefaultVariablesModal: (open: boolean) => void
}

const Settings: React.FC<SettingsMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setArchiveModal,
  addSection,
  setRestoreSectionModal,
  setDuplicateModal,
  setAuditModal,
  setPermissionsModal,
  setDefaultVariablesModal
}) => {
  const { project } = useProject()
  const { process, updateProcess, isAdmin } = useDataContext()

  const restoreProcess = () => {
    if (project && process) {
      api
        .restoreProcess(process.publicId, {
          projectId: project.publicId,
          context: { projectId: project.publicId }
        })
        .then(() => {
          updateProcess({ ...process, isDeleted: false })
        })
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div className="text-primary select-none">
        {isAdmin && process && (
          <div>
            {!process.isDeleted && (
              <div>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0px 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => addSection(null)}
                >
                  <Plus />
                  <span className="ml-10px">Add Section</span>
                </div>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0px 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    setAuditModal(true)
                  }}
                >
                  <Audit />
                  <span className="ml-10px">Audit Trail</span>
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0px 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    setPermissionsModal(true)
                  }}
                >
                  <Permissions />
                  <span className="ml-10px">Permissions</span>
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0px 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    setDefaultVariablesModal(true)
                  }}
                >
                  <Filter />
                  <span className="ml-10px">Default Variables</span>
                </div>

                <div className="flex items-center" style={{ padding: '5px 10px' }}>
                  <Variable />
                  <span className="ml-10px" style={{ marginRight: 'auto' }}>
                    Type
                  </span>
                  <div style={{ width: '200px' }}>
                    <ResourceType
                      handleChange={(value: string | null) => {
                        if (process) {
                          if (value) {
                            updateProcess({ ...process, type: value })
                          } else {
                            updateProcess({ ...process, type: '' })
                          }
                        }
                      }}
                      defaultValue={process && process.type ? process.type : ''}
                      helperText=""
                    />
                  </div>
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  title="This will allow any user with access to use this document as a template."
                >
                  <Template />
                  <span className="ml-10px">Set As Template</span>
                  <Toggle
                    toggled={process ? process.isTemplate : false}
                    onToggle={(toggle) => {
                      if (process) {
                        updateProcess({ ...process, isTemplate: toggle })
                      }
                    }}
                  />
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  title="This will expand all sections of the document by default when the document loads."
                >
                  <ExpandAll />
                  <span className="ml-10px">Expand Sections By Default</span>
                  <Toggle
                    toggled={process ? process.expandByDefault : false}
                    onToggle={(toggle) => {
                      if (process) {
                        updateProcess({ ...process, expandByDefault: toggle })
                      }
                    }}
                  />
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  title="This will allow users to comment on sections within this process."
                >
                  <Comment />
                  <span className="ml-10px">Enable Comments</span>
                  <Toggle
                    toggled={process ? process.allowComments : false}
                    onToggle={(toggle) => {
                      if (process) {
                        updateProcess({ ...process, allowComments: toggle })
                      }
                    }}
                  />
                </div>

                {isAdmin && (
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                    onClick={() => setDuplicateModal(true)}
                  >
                    <Copy />
                    <span className="ml-10px">Duplicate Document</span>
                  </div>
                )}

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0px 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    setRestoreSectionModal(true)
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }}
                >
                  <Restore />
                  <span className="ml-10px">Restore Section</span>
                </div>

                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => setArchiveModal(true)}
                >
                  <Delete />
                  <span className="ml-10px">Archive Document</span>
                </div>
              </div>
            )}

            {process.isDeleted && (
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => restoreProcess()}
              >
                <Restore />
                <span className="ml-10px">Restore Document</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Menu>
  )
}

export default Settings
