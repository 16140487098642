import React, { useEffect, useState } from 'react'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import DeleteResource from 'components/delete'
import { IFolderContents } from 'types'
import Duplicate from 'components/duplicate'
import Checkbox from 'components/checkbox'
import constants from 'style/constants.module.scss'
import { AI } from 'components/icons'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import { history } from 'helpers/history'
import { HOME } from 'routes'

const InformationBox: React.FC = () => {
  const { project, updateProject, projectOwner } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [archiveProjectDialog, setArchiveProjectDialog] = useState<boolean>(false)
  const [restoreProjectDialog, setRestoreProjectDialog] = useState<boolean>(false)
  const [permanentDeleteDialog, setPermanentDeleteDialog] = useState<boolean>(false)
  const [savingInfo, setSavingInfo] = useState<boolean>(false)
  const [editName, setEditName] = useState<{ editing: boolean; name: string }>({
    editing: false,
    name: project.name
  })
  const [processes, setProcesses] = useState<IFolderContents[]>([])
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false)
  const [deletingProject, setDeletingProject] = useState<boolean>(false)
  useEffect(() => {
    if (project.publicId !== '') {
      api.getProjectResources(project.publicId, { projectPermissions: true, excludeTables: true }).then((response) => {
        setProcesses(response.data)
      })
    }
  }, [project])

  const saveProjectInfo = (
    field: 'name' | 'defaultProcessId' | 'public' | 'mfaRequired' | 'hideProcessCreated' | 'aiSearchEnabled',
    value: string | boolean
  ) => {
    setSavingInfo(true)
    const newProjectInfo = { ...project }
    if (field === 'name' && typeof value === 'string') newProjectInfo['name'] = value
    else if (field === 'defaultProcessId' && typeof value === 'string') newProjectInfo['defaultProcessId'] = value
    else if (field === 'public' && typeof value === 'boolean') newProjectInfo['public'] = value
    else if (field === 'mfaRequired' && typeof value === 'boolean') newProjectInfo['mfaRequired'] = value
    else if (field === 'hideProcessCreated' && typeof value === 'boolean') newProjectInfo['hideProcessCreated'] = value
    else if (field === 'aiSearchEnabled' && typeof value === 'boolean') newProjectInfo['aiSearchEnabled'] = value

    api({
      method: 'PUT',
      endpoint: `/project/${project.publicId}`,
      data: {
        name: newProjectInfo.name,
        defaultProcessId: newProjectInfo.defaultProcessId,
        public: newProjectInfo.public,
        mfaRequired: newProjectInfo.mfaRequired,
        hideProcessCreated: newProjectInfo.hideProcessCreated,
        aiSearchEnabled: newProjectInfo.aiSearchEnabled
      }
    })
      .then(() => {
        updateProject(newProjectInfo)
        setEditName({ editing: false, name: newProjectInfo.name })
        setSavingInfo(false)
        setSnackbarMessage({
          status: 'success'
        })
      })
      .catch((e) => {
        displayErrorMessage(e)
        setSavingInfo(false)
      })
  }

  const deleteProject = () => {
    api
      .deleteProject(project.publicId)
      .then(() => {
        setArchiveProjectDialog(false)
        updateProject({
          ...project,
          isDeleted: true,
          deletedAt: Date.now().toString()
        })
      })
      .catch((error) => {
        displayErrorMessage(error)
        setArchiveProjectDialog(false)
      })
  }

  const restoreProject = () => {
    api
      .restoreProject({
        projectId: project.publicId
      })
      .then(() => {
        updateProject({
          ...project,
          isDeleted: false,
          deletedAt: null
        })
        setRestoreProjectDialog(false)
        setSnackbarMessage({
          status: 'success'
        })
      })
      .catch(() => {
        setSnackbarMessage({
          status: 'error'
        })
        setRestoreProjectDialog(false)
      })
  }

  const permanentDeleteProject = () => {
    setDeletingProject(true)
    api
      .permanentlyDeleteProject(project.publicId)
      .then(() => {
        setPermanentDeleteDialog(false)
        setDeletingProject(false)
        history.push(HOME)
      })
      .catch(() => {
        setSnackbarMessage({
          status: 'error'
        })
        setDeletingProject(false)
        setPermanentDeleteDialog(false)
      })
  }

  if (deletingProject) {
    return (
      <div
        className="w-full bg-light-grey"
        style={{ zIndex: 2, height: `calc(100% - ${Number.parseInt(constants.toolbarHeight)}px)` }}
      >
        <div className="spinner-chart">
          <div className="spin" style={{ height: 50, width: 50 }} />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full overflow-auto" style={{ padding: '30px 30px' }}>
      {projectOwner && (
        <div
          className="flex flex-column rounded"
          style={{ backgroundColor: constants.aiBackground, padding: '20px', border: '1px solid black' }}
        >
          <div
            className="flex flex-row items-center w-full text-lg font-bold"
            style={{ backgroundColor: constants.aiBackground, marginBottom: '20px' }}
          >
            <AI style={{ marginRight: '5px' }} />
            {capitaliseFirstLetter(PROJECT)} AI Settings
          </div>
          <p className="text-lg">
            Morta AI allows {PROJECT} members to ask questions and receieve an intelligent answer based on all the
            content within your {PROJECT} documents.
          </p>
          <Button
            internalType="gradient"
            className="text-base"
            style={{ width: '200px', padding: '10px', marginTop: '20px' }}
            onClick={() => saveProjectInfo('aiSearchEnabled', !project.aiSearchEnabled)}
            isLoading={savingInfo}
          >
            <AI style={{ marginRight: '5px' }} />
            {project.aiSearchEnabled ? 'Disable Morta AI' : 'Enable Morta AI'}
          </Button>
        </div>
      )}
      <div className="flex flex-column" style={{ padding: '20px' }}>
        <div className="mb-30px">
          <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
            General Settings
          </div>

          <div className="flex items-center">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Name:</div>
            <input
              disabled={!editName.editing}
              defaultValue={project.name}
              onChange={(event) => {
                if (!projectOwner) {
                  return
                }
                setEditName({ ...editName, name: event.target.value })
              }}
            />
            {!editName.editing && projectOwner && (
              <Button
                internalType="outline"
                style={{ marginLeft: '15px', color: 'black', border: '1px solid black' }}
                onClick={() => setEditName({ ...editName, editing: true })}
                disabled={savingInfo}
              >
                ✎
              </Button>
            )}

            {editName.editing && projectOwner && (
              <Button
                internalType="danger"
                style={{ marginLeft: '15px' }}
                onClick={() =>
                  setEditName({
                    name: project.name,
                    editing: false
                  })
                }
                disabled={savingInfo}
              >
                Cancel
              </Button>
            )}
            {editName.editing && projectOwner && (
              <Button
                style={{ marginLeft: '15px' }}
                onClick={() => saveProjectInfo('name', editName.name)}
                isLoading={savingInfo}
              >
                Save
              </Button>
            )}
          </div>
        </div>

        {projectOwner && (
          <div className="flex items-center mb-30px">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Default Document:</div>

            <select
              value={project.defaultProcessId}
              onChange={(event) => saveProjectInfo('defaultProcessId', event.target.value)}
            >
              <option value="">None Selected</option>
              {processes.map((folder: IFolderContents, folderNumber: number) => (
                <option key={folderNumber} value={folder.process?.publicId}>
                  {folder.process?.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {projectOwner && (
          <div className="flex items-center mb-30px">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Document Created By:</div>

            <Checkbox
              checked={project.hideProcessCreated}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                saveProjectInfo('hideProcessCreated', event.target.checked)
              }
            >
              Do Not Show Who The Document Was Created By
            </Checkbox>
          </div>
        )}

        {projectOwner && (
          <div className="flex items-center mb-30px">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Visibility:</div>

            {project.public ? (
              <Button internalType="accept" onClick={() => saveProjectInfo('public', false)}>
                Make {capitaliseFirstLetter(PROJECT)} Private
              </Button>
            ) : (
              <Button
                internalType="danger"
                onClick={() => saveProjectInfo('public', true)}
                data-cy="archive-project-button"
                disabled={savingInfo}
              >
                Make {capitaliseFirstLetter(PROJECT)} Public
              </Button>
            )}
          </div>
        )}

        {projectOwner && (
          <div className="flex items-center mb-30px">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Security:</div>

            {project.mfaRequired ? (
              <Button
                internalType="danger"
                onClick={() => saveProjectInfo('mfaRequired', false)}
                disabled={project.public}
              >
                Remove MFA Requirement
              </Button>
            ) : (
              <Button
                internalType="accept"
                onClick={() => saveProjectInfo('mfaRequired', true)}
                disabled={project.public}
              >
                Enforce MFA Requirement
              </Button>
            )}
          </div>
        )}

        {projectOwner && (
          <div className="flex items-center mb-30px">
            <div style={{ minWidth: '200px', maxWidth: '200px', marginRight: '10px' }}>Duplication:</div>
            <Button internalType="accept" onClick={() => setDuplicateModal(true)}>
              Duplicate {capitaliseFirstLetter(PROJECT)}
            </Button>
            {duplicateModal && (
              <Duplicate
                id="duplicate-project"
                type={PROJECT}
                publicId={project.publicId}
                open={duplicateModal}
                setOpen={setDuplicateModal}
              />
            )}
          </div>
        )}
      </div>

      {projectOwner && (
        <div style={{ padding: '20px', marginBottom: '40px' }}>
          <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
            Deactivate {capitaliseFirstLetter(PROJECT)}
          </div>
          {project.isDeleted ? (
            <div className="mb-15px">
              Your {PROJECT} is not currently active - this means that new tables and documents will not be able to be
              added and key functionality is not available.
            </div>
          ) : (
            <div className="mb-15px">
              Your {PROJECT} is currently active - this means that new tables and documents can be added and all major
              functionality will be operational. You can deactivate your {capitaliseFirstLetter(PROJECT)} if you wish to
              stop all activity and prevent new tables and documents from being added.
            </div>
          )}
          {project.isDeleted ? (
            <Button internalType="danger" onClick={() => setRestoreProjectDialog(true)} disabled={savingInfo}>
              Make Project Active
            </Button>
          ) : (
            <Button
              internalType="danger"
              onClick={() => setArchiveProjectDialog(true)}
              data-cy="archive-project-button"
              disabled={savingInfo}
            >
              Deactivate {capitaliseFirstLetter(PROJECT)}
            </Button>
          )}
        </div>
      )}

      {projectOwner && (
        <div
          style={{
            padding: '20px',
            backgroundColor: constants.reallyLightRed,
            border: `1px solid ${constants.lightRed}`
          }}
        >
          <div className="flex flex-row items-center w-full text-lg font-bold mb-20px py-10px border-b-2px border-solid border-grey">
            <span style={{ color: constants.lightRed, fontSize: '140%', marginRight: '5px' }}>⚠</span> Permanently
            Delete {capitaliseFirstLetter(PROJECT)}{' '}
            <span style={{ color: constants.lightRed, fontSize: '140%', marginLeft: '5px' }}>⚠</span>
          </div>

          <div className="mb-15px">
            Be very careful - this will permanently delete all data associated with this {PROJECT} and cannot be undone.
            Make sure you have backed up any important data before proceeding with this action.
          </div>

          <Button
            internalType="danger"
            onClick={() => setPermanentDeleteDialog(true)}
            data-cy="archive-project-button"
            disabled={savingInfo || !project.isDeleted}
            title={!project.isDeleted ? `You must deactivate the ${PROJECT} before you can permanently delete it` : ''}
          >
            Permanently Delete {capitaliseFirstLetter(PROJECT)}
          </Button>
        </div>
      )}

      {archiveProjectDialog && (
        <DeleteResource
          id="archive-project-modal"
          open={archiveProjectDialog}
          setOpen={setArchiveProjectDialog}
          resourceName={project.name}
          deleteResource={() => deleteProject()}
        />
      )}

      {permanentDeleteDialog && (
        <DeleteResource
          id="permanent-delete-project-modal"
          open={permanentDeleteDialog}
          setOpen={setPermanentDeleteDialog}
          resourceName={`I want to delete ${project.name}`}
          deleteResource={() => permanentDeleteProject()}
          notUndo={true}
          disableCopy={true}
        />
      )}

      {restoreProjectDialog && (
        <DeleteResource
          id="restore-project-modal"
          open={restoreProjectDialog}
          setOpen={setRestoreProjectDialog}
          resourceName={project.name}
          deleteResource={() => restoreProject()}
          restoring={true}
        />
      )}
    </div>
  )
}

export default InformationBox
